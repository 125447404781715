<template>
    <div class="flex flex-col w-full">

        <div class="flex items-center justify-between flex-shrink-0 sticky top-0 bg-white px-6 py-6 flex-shrink-0">
            <div class="text-gray-800 text-2xl">Suppliers</div>
            <div class="flex">
                <div v-if="!loading && suppliers && suppliers.length > 0">
                    <vue-excel-xlsx :data="convertToXlsxData.data"
                                    :columns="convertToXlsxData.columns"
                                    :filename="convertToXlsxData.fileName"
                                    :sheetname="convertToXlsxData.sheetName">
                        <div class="flex items-center default-button border border-gray-300"
                             style="height: 38px">
                            <i class="fal fa-download"/>
                            <span class="hidden sm:block sm:ml-2">Excel</span>
                        </div>
                    </vue-excel-xlsx>
                </div>

                <button @click="addNew"
                        class="primary-button-dark flex lg:hidden items-center justify-center ml-2"
                        style="height: 38px">
                    <i class="fal fa-plus"/>
                </button>
            </div>

        </div>


        <div class="flex flex-1 w-full">
            <autralis-loading-comp v-if="loading" size="sm"/>
            <div v-else-if="!loading && loadingError" class="flex rounded-md bg-red-50 p-4 items-center">
                <i class="fal fa-exclamation-circle text-red-400 mr-3"/>
                <h3 class="text-sm font-medium text-red-800">{{ loadingError }}</h3>
            </div>


            <div v-else-if="!loading && !loadingError && suppliers && suppliers.length < 1" class="flex rounded-md p-4 items-start justify-center w-full">
                <div class="flex items-center justify-center">
                    <i class="fal fa-exclamation-circle text-gray-400 mr-3"/>
                    <h3 class="text-sm text-gray-600">No suppliers to show.</h3>
                </div>
            </div>

            <div v-else class="flex w-full ">
                <div class="suppliers-list block lg:flex flex-col w-full">
                    <div v-for="supplier in suppliers" :key="supplier.id"
                         :class="['flex flex-col w-full items-center justify-center truncate border-b border-gray-200 px-6 py-2', isSelected(supplier.id) ? 'bg-gray-50' : '']">
                        <div class="flex w-full">
                            <div :class="['hover:text-blue-500 cursor-pointer w-full truncate font-semibold', isSelected(supplier.id) ? 'text-blue-600 underline' : 'text-gray-600']"
                                 @click="setSelected(supplier.id)"
                                 style="font-size: 15px">
                                {{ supplier.name }}
                            </div>
                            <div style="width: 30px;" class="flex items-center justify-center text-xs hover:underline cursor-pointer uppercase" @click="onEdit(supplier.id)">
                                Edit
                            </div>
                        </div>

                        <div v-if="mappedStatus(supplier.state)"
                             :class="'flex justify-start w-full text-xs text-gray-500 truncate tracking-wider'">
                            {{ mappedStatus(supplier.state).label }}
                        </div>

                        <div :class="['flex flex-col transition-all ease-in-out duration-150 w-full items-start pl-3 space-y-1 mt-1', isSelected(supplier.id) ? 'opacity-100' : 'opacity-0']"
                             :style="isSelected(supplier.id) ? 'height:' + dashboards.length * 23 + 'px' : 'height: 0px'">
                            <div v-for="dashboard in dashboards"
                                 :key="dashboard.key"
                                 :class="['flex items-center cursor-pointer hover:text-blue-500', isDashboardSelected(dashboard.key) ? 'font-semibold' : '']"
                                 @click="selectDashboard(dashboard.key)"
                            >
                                <div style="min-width: 20px"><i :class="[dashboard.icon, 'mr-1 text-xs']"/></div>
                                {{ dashboard.name }}
                                <!--                            <i class="fal fa-scrubber ml-2" v-if="isDashboardSelected(dashboard.key)" style="font-size: 8px;"/>-->
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>


        <div class="hidden lg:flex items-center justify-end flex-shrink-0 sticky bottom-0 bg-white w-full py-3 px-6">
            <button @click="addNew"
                    class="primary-button-dark flex items-center justify-center"
                    style="height: 38px">
                <i class="fal fa-plus mr-1"/>
                <span>Add supplier</span>
            </button>
        </div>
    </div>
</template>

<script>
import Loading from "@/components/loading";
import {handleFetchSuppliersV2} from "@/master/views/Home/suppliers/handlers";
import {find, head, orderBy, map} from "lodash";
import {DASHBOARD_KEYS, DETAIL_PANEL_KEYS} from "@/master/constants";
import {STATES} from "@/master/views/Home/states-and-triggers";
// import {HOME_LIST_ITEMS_PER_PAGE} from "@/master/constants";

const DASHBOARDS = [
    {key: DASHBOARD_KEYS.SUPPLIERS_ADDRESSES, name: "Addresses", icon: "fal fa-map-marker-alt", default: true},
    {key: DASHBOARD_KEYS.SUPPLIERS_USERS, name: "Users", icon: "fal fa-users", default: false}
]

export default {
    name: "Panel",

    data() {
        return {
            loading: false,
            loadingError: null
        }
    },

    computed: {
        suppliers() {
            return this.$store.getters['master/suppliers'];
        },

        dashboards() {
            return DASHBOARDS
        },

        selectedSupplier() {
            return this.$store.getters['master/selectedSupplier']
        },

        convertToXlsxData() {
            let fileName = this.$store.getters['master/company'].name + ' suppliers';
            const sheetName = 'suppliers'

            const columns = [
                {label: "Name", field: "name"},
                {label: "Country", field: "countryCode"},
                {label: "Vat number", field: "vatNumber"},
                {label: "Status", field: "state"},
            ]

            const ordered = this.orderedSuppliers(this.$store.getters['master/suppliers']);
            const mappedData = map(ordered, supplier => {
                return {
                    'name': supplier.name,
                    'countryCode': supplier.countryCode,
                    'vatNumber': supplier.vatNumber,
                    'state': STATES[supplier.state].label
                }
            })

            return {
                columns: columns,
                data: mappedData,
                fileName,
                sheetName
            }
        },
    },

    methods: {
        async fetchSuppliers() {
            try {
                this.loading = true;
                this.loadingError = null

                const payload = {};

                await handleFetchSuppliersV2(payload, ({suppliers}) => {

                    const orderedSuppliers = this.orderedSuppliers(suppliers);
                    this.$store.commit('master/setSuppliers', orderedSuppliers);
                    this.setSelected(head(orderedSuppliers).id);
                }, (error) => {
                    this.loadingError = error
                })

                this.loading = false;

            } catch (err) {
                this.loadingError = 'The suppliers could not be fetched. Please try again.'
                this.loading = false;
            }
        },

        mappedStatus(status) {
            return STATES[status] ? STATES[status] : null;
        },

        orderedSuppliers(suppliers) {
            return orderBy(suppliers, ['name'], ['asc']);
        },

        setSelected(id) {
            const selectedID = this.$store.getters['master/detail'] ? this.$store.getters['master/detail'].id : id
            this.$store.commit('master/setSelectedSupplier', selectedID);
        },

        isSelected(id) {
            return this.selectedSupplier === id;
        },

        async addNew() {
            const detailPayload = {
                key: DETAIL_PANEL_KEYS.EDIT_SUPPLIER
            }
            this.$store.commit('master/setDetail', detailPayload);
            this.$store.commit('master/openDetail');
        },

        onEdit(id) {
            const detailPayload = {
                key: DETAIL_PANEL_KEYS.EDIT_SUPPLIER,
                id: id
            }
            this.setSelected(id);
            this.$store.commit('master/setDetail', detailPayload);
            this.$store.commit('master/openDetail');
        },

        selectDashboard: function (dashboardKey) {
            this.$store.commit('master/setDashboard', dashboardKey);
        },

        isDashboardSelected(dashboardKey) {
            return this.$store.getters['master/dashboard'] === dashboardKey
        },

        initializeDashboard() {
            const defaultDashboard = find(this.dashboards, dashboard => dashboard.default);
            this.selectDashboard(defaultDashboard.key);
        }
    },

    created() {
        this.fetchSuppliers();
        this.initializeDashboard();
    },

    components: {
        'autralis-loading-comp': Loading,
    }
}
</script>

<style scoped>
@media (max-width: 1023px) {
    .suppliers-list {
        height: 220px;
        overflow-y: scroll;
    }
}
</style>